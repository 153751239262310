import { BASE, CURRENT_STORE } from ".";

interface IStoreAPI {
  local?: string;
  homolog?: string;
  prod?: string;
}

interface IStoresAPIs {
  [key: string]: IStoreAPI;
}

export interface IStoreColors {
  primary?: string;
  primaryDarker?: string;
  primaryLighter?: string;
  primaryHover?: string;
  secondary?: string;
  cardColor?: string;
  cardBorder?: string;
  formBackground?: string;
  opacityBackground?: string;
  whiteOpacityBackground?: string;
  primaryTextColor?: string;
  primaryComplementaryTextColor?: string;
  inputBorderFocused?: string;
  inputBorderBlurred?: string;
  background?: string;
  white?: string;
  black?: string;
  muted?: string;
  disabled?: string;
  warning?: string;
  success?: string;
  menuBackground?: string;
}

interface IStoresColors {
  [key: string]: IStoreColors;
}

interface IStoresVersions {
  [key: string]: string;
}

const StoresAPIs: IStoresAPIs = {
  local: {
    local: "http://localhost:3001/api/v1",
    homolog: "https://api-erp.devaribox.co/api/v1",
    prod: "https://api-erp.devaribox.co/api/v1",
  },
  gmdimensional: {
    local: "https://api-gmdimensional.devari.com.br/api/v1",
    homolog: "https://api-gmdimensional.devari.com.br/api/v1",
    prod: "https://api-gmdimensional.devari.com.br/api/v1",
  },
  rizar: {
    local: "https://api.rizar.com.br/api/v1",
    homolog: "https://api.rizar.com.br/api/v1",
    prod: "https://api.rizar.com.br/api/v1",
  },
  bedutech: {
    local: "https://api.rizar.com.br/api/v1",
    homolog: "https://bedutech-api.devaribox.co/api/v1",
    prod: "https://api.rizar.com.br/api/v1",
  },
};

const StoresColors: IStoresColors = {
  local: {
    primary: "#2C7D95",
    primaryDarker: "#2B4C84",
    primaryLighter: "#2DACC6",
    secondary: "#FF8A00",
    cardColor: "#eed8aa",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#555",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    menuBackground: "#3C4B64",
  },
  rizar: {
    primary: "#2C7D95",
    primaryDarker: "#2B4C84",
    primaryLighter: "#2DACC6",
    secondary: "#FF8A00",
    cardColor: "#eed8aa",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#555",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    menuBackground: "#3C4B64",
  },
  gmdimensional: {
    primary: "#2C7D95",
    primaryDarker: "#2B4C84",
    primaryLighter: "#2DACC6",
    secondary: "#FF8A00",
    cardColor: "#eed8aa",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#555",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "rgba(0, 0, 0, 0.2)",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    menuBackground: "#3C4B64",
  },
  bedutech: {
    primary: "#3391ff",
    primaryDarker: "#005a87",
    primaryLighter: "#3ab8f0",
    primaryHover: "#062836",
    secondary: "#54595f",
    cardColor: "#eed8aa",
    cardBorder: "#E5E5E5",
    formBackground: "rgba(255, 255, 255, 0.7)",
    opacityBackground: "rgba(0, 0, 0, .1)",
    whiteOpacityBackground: "rgba(255, 255, 255, 0.5)",
    primaryTextColor: "#c9c4bd",
    primaryComplementaryTextColor: "#FFFFFF",
    inputBorderBlurred: "#404548",
    inputBorderFocused: "#2C7D95",
    white: "#FFFFFF",
    black: "#000000",
    muted: "#888",
    disabled: "#ccc",
    background: "#EEF2F4",
    warning: "red",
    success: "#58e463",
    menuBackground: "#3C4B64",
  },
};

const StoresVersions: IStoresVersions = {
  local: "1.0",
  rizar: "1.0",
  gmdimensional: "1.0",
  bedutech: "1.0",
};

export const BASE_URL = StoresAPIs[CURRENT_STORE][BASE];
export const COLORS = StoresColors[CURRENT_STORE];
export const VERSION = StoresVersions[CURRENT_STORE];
