import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Menu, Div, ImageUser, Logo, Button } from "./styles";
import { IoMenu, IoNotifications } from "react-icons/io5";
import LogoImage from "../../assets/images/LOGOBEDUTECH.svg";

import { useMenu } from "../../contexts/menu";
import { useAuth } from "../../contexts/auth";

const Header: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { toggleLeftMenu, toggleRightMenu } = useMenu();

  return (
    <Container>
      <Div>
        <Menu onClick={toggleLeftMenu}>
          <IoMenu size={24} color="var(--primary-text-color)" />
        </Menu>
        <Logo
          onClick={() => history.push("/")}
          src={LogoImage}
          alt="Nome Devari escrito em cor azul e abaixo dele o nome Tecnologia escrito em laranja."
        />
      </Div>
      {/* <Div>
        <InputSearch placeholder="Pesquise..." />
        <IoSearchSharp style={{ marginLeft: -25, color: 'var(--muted)', cursor: 'pointer', fontSize: '1.2rem' }} />
      </Div> */}
      <Div>
        <Button onClick={() => toggleRightMenu("notificação")}>
          <IoNotifications size={24} color="var(--primary-text-color)" />
        </Button>
        <Button onClick={() => toggleRightMenu("perfil")}>
          <ImageUser src={user?.foto || ""} />
        </Button>
      </Div>
    </Container>
  );
};

export default Header;
