enum Bases {
  LOCAL = "local",
  HOMOLOG = "homolog",
  PROD = "prod",
}

let BASE: Bases = Bases.LOCAL;

const currentDomain = window.location.href;

if (currentDomain.includes("devaribox.co")) {
  BASE = Bases.HOMOLOG;
} else if (currentDomain.includes("devari.com")) {
  BASE = Bases.PROD;
} else {
  BASE = Bases.HOMOLOG;
}

let CURRENT_STORE = "bedutech";

if (currentDomain.includes("gmdimensional")) {
  CURRENT_STORE = "gmdimensional";
} else if (currentDomain.includes("rizar")) {
  CURRENT_STORE = "rizar";
} else if (currentDomain.includes("bedutech")) {
  CURRENT_STORE = "bedutech";
}

export { BASE, CURRENT_STORE };
