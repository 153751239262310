import React, { FunctionComponent } from "react";
import {
  Container,
  Title,
  Content,
  Card,
  CardSkill,
  DivMain,
  Link,
  LinkPrimary,
  DivLeft,
  DivRight,
  DivStorage,
  DivRow,
  DivColumn,
} from "./styles";
import Users from "../../assets/images/users.png";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import Gmail from "../../assets/images/gmail.png";
import Calendar from "../../assets/images/google-calendar.png";
import Drive from "../../assets/images/google-drive.png";
import Docs from "../../assets/images/docs.png";
import Sheets from "../../assets/images/sheets.png";
import Keep from "../../assets/images/keep.png";
import Classroom from "../../assets/images/google-classroom.png";
import Meet from "../../assets/images/meet.png";
import { BsFillCapslockFill } from "react-icons/bs";

const Home: React.FC = () => {
  const data = [
    { name: "Memory", value: 70 },
    { name: "Space", value: 30 },
  ];
  const colors = ["#760fcb", "#de009e", "#4C3EC4"];

  const dataGmail = [
    {
      name: "Dispositivos",
      uv: 8,
    },
    {
      name: "Segurança",
      uv: 15,
    },
    {
      name: "Apps",
      uv: 13,
    },
  ];

  function CustomTooltipPie({ active, payload, label }: any) {
    if (active && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>{payload[0].name}</h4>
          <p>{payload[0].value}%</p>
        </div>
      );
    }
    return null;
  }

  const CustomizedAxisTickBar: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    let date = payload.value !== "auto" ? payload.value : null;
    if (date !== null) {
      let newDate = date.substr(5, 5);
      newDate = newDate.split("-").reverse().join("-").replace("-", "/");
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="var(--white)"
            transform="rotate(-35)"
          >
            {newDate}
          </text>
        </g>
      );
    }
    return null;
  };

  function CustomTooltipBar({ active, payload, label }: any) {
    if (active && label && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>{label}</h4>
          <p>Qtd.: {payload[0].payload.uv}</p>
        </div>
      );
    }
    return null;
  }

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${percent * 100}%`}
      </text>
    );
  };

  return (
    <Container>
      <Title>Home</Title>
      <Content>
        <DivLeft>
          <Card heigh={"50px"} minWide={"370px"}>
            <p>
              Instituição: <b>id.uff.br</b>
            </p>
          </Card>
          <Card heigh={"50px"} minWide={"370px"}>
            <p>Utilização nos últimos 30 dias</p>
          </Card>
          <Card minWide={"370px"}>
            <img src={Users} width={150} height={130} alt="" />
            <div className="content">
              <h3>Usuários</h3>
              <p>132.808</p>
              <h3>Desabilitados</h3>
              <p>38</p>
            </div>
            <div className="footer"></div>
          </Card>
          <div className="text">
            <p>
              Para domínios com mais de 20 mil usuários ativos, o armazenamento
              base será determinado a partir do número e3 usuário, devendo ser
              considerado 5GB para cada usuário ativo.
            </p>
          </div>
          <CardSkill>
            <table cellSpacing={0}>
              <thead>
                <tr>
                  <th>Licenças</th>
                  <th>L. Adquirido</th>
                  <th>L. Atribuídas</th>
                  <th>Cota Adicional (TB)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Workspace for Education Plus</td>
                  <td>62.577</td>
                  <td>60.695</td>
                  <td>1.252,54</td>
                </tr>
                <tr>
                  <td>Google Workspace for Education Plus (Staff)</td>
                  <td>15.645</td>
                  <td>7.141</td>
                  <td>312,9</td>
                </tr>
                <tr>
                  <td>Google Workspace for Education Fundamentals</td>
                  <td>2.000.000</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Geral</td>
                  <td>2.078.222</td>
                  <td>67.836</td>
                  <td>1.564,44</td>
                </tr>
              </tfoot>
            </table>
          </CardSkill>
          <h2>Utilização por ferramenta</h2>
          <p>
            Os números representam os últimos 30 dias e as barras o último
            trimestre.
          </p>
          <CardSkill>
            <div className="graphics">
              <div className="graphic">
                <div>
                  <img src={Gmail} alt="Logo do gmail" width={50} height={40} />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>68.433</p>
                <span className="success">
                  <BsFillCapslockFill />
                  0.3%
                </span>
              </div>
              <div className="graphic">
                <div>
                  <img
                    src={Calendar}
                    alt="Logo do google calendar"
                    width={50}
                    height={40}
                  />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>31.667</p>
                <span className="warning">
                  <BsFillCapslockFill />
                  -5.3%
                </span>
              </div>
              <div className="graphic">
                <div>
                  <img
                    src={Drive}
                    alt="Logo do google drive"
                    width={50}
                    height={40}
                  />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>51.662</p>
                <span className="warning">
                  <BsFillCapslockFill />
                  -1.2%
                </span>
              </div>
              <div className="graphic">
                <div>
                  <img
                    src={Docs}
                    alt="Logo do google docs"
                    width={50}
                    height={40}
                  />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>32.322</p>
                <span className="success">
                  <BsFillCapslockFill />
                  2.7%
                </span>
              </div>
              <div className="graphic">
                <div>
                  <img
                    src={Sheets}
                    alt="Logo do google sheets"
                    width={50}
                    height={40}
                  />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>16.737</p>
                <span className="success">
                  <BsFillCapslockFill />
                  0.4%
                </span>
              </div>
              <div className="graphic">
                <div>
                  <img
                    src={Keep}
                    alt="Logo do google keep"
                    width={50}
                    height={40}
                  />
                  <ResponsiveContainer
                    width={"90%"}
                    height={60}
                    className="dailyProfit"
                  >
                    <BarChart
                      data={dataGmail}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 2,
                      }}
                      height={60}
                      barSize={20}
                    >
                      {/* <XAxis
                        dataKey="name"
                        scale="point"
                        stroke="var(--white)"
                        padding={{ left: 12, right: 10 }}
                        tick={<CustomizedAxisTickBar />}
                      /> */}
                      <YAxis stroke="var(--muted)" />
                      {/* <Tooltip content={CustomTooltipBar} /> */}
                      {/* <Legend /> */}
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="var(--muted)"
                      />
                      <Bar
                        dataKey="uv"
                        fill="var(--primary)"
                        background={{ fill: "#eee" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p>7.542</p>
                <span className="success">
                  <BsFillCapslockFill />
                  10.4%
                </span>
              </div>
            </div>
          </CardSkill>
        </DivLeft>
        <DivRight>
          <Card wide="100%">
            <ResponsiveContainer width={"50%"}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  labelLine={true}
                  label
                  // label={renderCustomizedLabel}
                  cx="50%"
                  cy="50%"
                  outerRadius={64}
                  innerRadius={34}
                >
                  {data.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="top" height={36} />
                <Tooltip content={CustomTooltipPie} />
              </PieChart>
            </ResponsiveContainer>
            <p className="right">
              Este termômetro simboliza a adoção das principais ferramentas de
              produtividade. O percentual esta representado na média da
              utilização nos últimos 30 dias.
            </p>
          </Card>
          <DivStorage>
            <div className="cards">
              <div className="card">
                <span>Total Armazenamento (TB)</span>
                <p>4.137,51</p>
              </div>
              <div className="card">
                <span>Cota Limite (TB)</span>
                <p>2.228,58</p>
              </div>
              <div className="card">
                <span>Espaço Restante (TB)</span>
                <p className="danger">-1.908,94</p>
              </div>
            </div>
            <div>
              <p>
                O armazenamento possui um limite. Você deve controlá-lo de
                acordo com seu licenciamento. Caso tenha ultrapassado seu
                limite, seguem alguns procedimentos para ajudá-lo.
              </p>
            </div>
          </DivStorage>
          <CardSkill mTop={"28px"}>
            <div className="graphics">
              <div className="graphic right">
                <DivRow>
                  <DivColumn wide={"55%"}>
                    <img
                      src={Classroom}
                      alt="Logo do google classroom"
                      width={50}
                      height={40}
                    />
                    <p className="description">Estudantes ativos</p>
                    <p>68.433</p>
                    <span className="success">
                      <BsFillCapslockFill />
                      0.3%
                    </span>
                  </DivColumn>
                  <DivColumn wide={"45%"}>
                    <ResponsiveContainer
                      width={"90%"}
                      height={60}
                      className="dailyProfit"
                    >
                      <BarChart
                        data={dataGmail}
                        margin={{
                          top: 5,
                          right: 0,
                          left: 0,
                          bottom: 2,
                        }}
                        height={60}
                        barSize={20}
                      >
                        {/* <XAxis
                          dataKey="name"
                          scale="point"
                          stroke="var(--white)"
                          padding={{ left: 12, right: 10 }}
                          tick={<CustomizedAxisTickBar />}
                        /> */}
                        <YAxis stroke="var(--muted)" />
                        {/* <Tooltip content={CustomTooltipBar} /> */}
                        {/* <Legend /> */}
                        <CartesianGrid
                          strokeDasharray="3 3"
                          stroke="var(--muted)"
                        />
                        <Bar
                          dataKey="uv"
                          fill="var(--primary)"
                          background={{ fill: "#eee" }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                    <p className="description">Professores ativos</p>
                    <p>68.433</p>
                    <span className="success">
                      <BsFillCapslockFill />
                      0.3%
                    </span>
                  </DivColumn>
                </DivRow>
              </div>
              <div className="graphic right">
                <DivRow>
                  <DivColumn wide={"55%"}>
                    <img
                      src={Meet}
                      alt="Logo do google classroom"
                      width={50}
                      height={40}
                    />
                    <p className="description">Estudantes ativos</p>
                    <p>68.433</p>
                    <span className="success">
                      <BsFillCapslockFill />
                      0.3%
                    </span>
                  </DivColumn>
                  <DivColumn wide={"45%"}>
                    <ResponsiveContainer
                      width={"90%"}
                      height={60}
                      className="dailyProfit"
                    >
                      <BarChart
                        data={dataGmail}
                        margin={{
                          top: 5,
                          right: 0,
                          left: 0,
                          bottom: 2,
                        }}
                        height={60}
                        barSize={20}
                      >
                        {/* <XAxis
                          dataKey="name"
                          scale="point"
                          stroke="var(--white)"
                          padding={{ left: 12, right: 10 }}
                          tick={<CustomizedAxisTickBar />}
                        /> */}
                        <YAxis stroke="var(--muted)" />
                        {/* <Tooltip content={CustomTooltipBar} /> */}
                        {/* <Legend /> */}
                        <CartesianGrid
                          strokeDasharray="3 3"
                          stroke="var(--muted)"
                        />
                        <Bar
                          dataKey="uv"
                          fill="var(--primary)"
                          background={{ fill: "#eee" }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                    <p className="description">Professores ativos</p>
                    <p>68.433</p>
                    <span className="success">
                      <BsFillCapslockFill />
                      0.3%
                    </span>
                  </DivColumn>
                </DivRow>
              </div>
            </div>
          </CardSkill>
        </DivRight>
      </Content>
    </Container>
  );
};

export default Home;
