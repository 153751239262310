import React from "react";
import { Card, Container, DivResume, DivRow, Filters, Table } from "./style";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  ReferenceLine,
} from "recharts";

const Dashboard: React.FC = () => {
  const data = [
    { name: "Maturidade", value: 52 },
    { name: "Conta", value: 48 },
  ];

  const dataBar = [
    { name: "", uv: -1.5 },
    { name: "", uv: -1 },
    { name: "", uv: 0 },
    { name: "", uv: 1 },
    { name: "", uv: 1.5 },
  ];

  const dataPie = [
    { name: "Média", value: 27 },
    { name: "Baixa", value: 36.5 },
    { name: "Alta", value: 36.5 },
  ];
  const colors = ["#f6aa33", "#2abe81", "red"];

  const dataGauge = [
    { name: "Esforço", value: 32 },
    { name: "Diretório", value: 68 },
  ];
  const colorsGauge = ["#760fcb", "#de009e"];

  const dataBarEffort = [
    { name: "Alta", uv: 44 },
    { name: "Baixa", uv: 44 },
    { name: "Média", uv: 38 },
  ];

  const dataBarMaturityLevel = [
    { name: "Conta", uv: 80 },
    { name: "Diretório", uv: 80 },
    { name: "Relatórios", uv: 53.33 },
    { name: "Apps", uv: 50 },
    { name: "Segurança", uv: 46.67 },
    { name: "Dispositivos", uv: 42.86 },
  ];

  function CustomTooltipPie({ active, payload, label }: any) {
    if (active && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>{payload[0].name}</h4>
          <p>{payload[0].value}%</p>
        </div>
      );
    }
    return null;
  }

  function CustomTooltipBar({ active, payload, label }: any) {
    if (active && payload.length > 0) {
      return (
        <div className="tooltip">
          <h4>{payload[0].payload.name}</h4>
          <p>Qtd.: {payload[0].value}</p>
        </div>
      );
    }
    return null;
  }

  return (
    <Container>
      <DivRow>
        <Filters>
          <p>Filtros</p>
          <Card>
            <p>Data do Assesment</p>
            <p>1 de jul de 2022</p>
            <p>1 de jun de 2022</p>
          </Card>
          <select name="MenuPrincipal" id="MenuPrincipal">
            <option value="Menu Principal">Menu Principal</option>
          </select>
          <select name="item" id="item">
            <option value="item">Item</option>
          </select>
        </Filters>
        <Filters wide={"250px"}>
          <p>Resumo</p>
          <DivResume>
            <span>Total do Assessment</span>
            <p>2</p>
          </DivResume>
          <DivResume>
            <span>Total de Itens Analisados</span>
            <p>128</p>
          </DivResume>
        </Filters>
        <Filters wide="60%" white>
          <p>Maturidade</p>
          <div className="graphic">
            <ResponsiveContainer width={"50%"}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  labelLine={true}
                  label
                  // label={renderCustomizedLabel}
                  cx="50%"
                  cy="50%"
                  outerRadius={64}
                >
                  {data.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="top" height={36} />
                <Tooltip content={CustomTooltipPie} />
              </PieChart>
            </ResponsiveContainer>
            <div className="description">
              <p>
                A maturidade abaixo de um percentual médio de 50%, gera muitos
                riscos para o negócio. Vazamento de informações, desorganização,
                aumento de custos operacionais, como manter contas ativas
                desnecessariamente, armazenamento indevido entre outros
                aspectos.
              </p>
              <p>
                Quando o cliente possui algum processo, uma estrutura
                organizacional, controle de acesso através de grupos, uma gestão
                de acesso a arquivos, ela se encontra com o bom nível de
                maturidade, isso dentro do assessment está pontuado entre 50% e
                80%.
              </p>
              <p>
                Com regras de alertas, automatizações, implementação das
                políticas de segurança através de DLP, por exemplo. Mostra uma
                maturidade ainda maior o cliente está em um bom nível, e deve
                focar na manutenção e melhoria contínua do processo.
              </p>
            </div>
            <ResponsiveContainer
              width={"90%"}
              height={100}
              className="dailyProfit"
            >
              <BarChart
                data={dataBar}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 2,
                }}
                height={100}
                barSize={20}
              >
                <YAxis stroke="var(--muted)" />
                <Tooltip content={CustomTooltipBar} />
                <CartesianGrid strokeDasharray="3 3" stroke="var(--muted)" />
                <ReferenceLine y={0} stroke="#000" />
                <Bar
                  dataKey="uv"
                  fill="var(--primary)"
                  background={{ fill: "#eee" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Filters>
      </DivRow>
      <DivRow>
        <Filters wide={"40%"} white>
          <p>Criticidade</p>
          <ResponsiveContainer width={"50%"}>
            <PieChart>
              <Pie
                data={dataPie}
                dataKey="value"
                nameKey="name"
                labelLine={true}
                label
                // label={renderCustomizedLabel}
                cx="50%"
                cy="50%"
                outerRadius={64}
                innerRadius={34}
              >
                {data.map((entry: any, index: any) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Legend verticalAlign="top" height={36} />
              <Tooltip content={CustomTooltipPie} />
            </PieChart>
          </ResponsiveContainer>
        </Filters>
        <Filters wide={"60%"} white>
          <p>Esforço</p>
          <div className="graphic">
            <ResponsiveContainer width={"50%"}>
              <PieChart>
                <Pie
                  data={dataGauge}
                  dataKey="value"
                  nameKey="name"
                  labelLine={true}
                  label
                  // label={renderCustomizedLabel}
                  cx="50%"
                  cy="50%"
                  outerRadius={64}
                >
                  {data.map((entry: any, index: any) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colorsGauge[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="top" height={36} />
                <Tooltip content={CustomTooltipPie} />
              </PieChart>
            </ResponsiveContainer>
            <div className="description">
              <p>
                O esforço para implementação das melhorias ou correções das
                issues, deve ser definido através de decisão estratégica, focada
                nos pilares de risco,custo e impacto para o negócio.
              </p>
            </div>
            <ResponsiveContainer
              width={"90%"}
              height={100}
              className="dailyProfit"
            >
              <BarChart
                data={dataBarEffort}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 2,
                }}
                height={100}
                barSize={20}
              >
                <YAxis stroke="var(--muted)" />
                <Tooltip content={CustomTooltipBar} />
                <CartesianGrid strokeDasharray="3 3" stroke="var(--muted)" />
                <Bar
                  dataKey="uv"
                  fill="var(--primary)"
                  background={{ fill: "#eee" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Filters>
      </DivRow>
      <DivRow>
        <Filters wide={"100%"} white>
          <p>Nível de Maturidade por menu</p>
          <div className="graphicMaturity">
            <ResponsiveContainer
              width={"90%"}
              height={280}
              className="dailyProfit"
            >
              <BarChart
                data={dataBarMaturityLevel}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 2,
                }}
                height={280}
                barSize={50}
              >
                <YAxis stroke="var(--muted)" />
                <Tooltip content={CustomTooltipBar} />
                <CartesianGrid strokeDasharray="3 3" stroke="var(--muted)" />
                <Bar
                  dataKey="uv"
                  fill="var(--primary)"
                  background={{ fill: "#eee" }}
                />
              </BarChart>
            </ResponsiveContainer>
            <Table>
              <thead>
                <tr>
                  <th> </th>
                  <th>Menu Principal</th>
                  <th>Maturidade</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Faturamento</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Regras</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>Diretório</td>
                  <td>null</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>Conta</td>
                  <td>null</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>Relatórios</td>
                  <td>Média</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>Apps</td>
                  <td>Média</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>Segurança</td>
                  <td>Média</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Filters>
      </DivRow>
      <DivRow>
        <Filters wide={"100%"} white>
          <p>Detalhamento</p>
          <div className="tableDetailing">
            <Table>
              <thead>
                <tr>
                  <th> </th>
                  <th>Data</th>
                  <th>Menu Principal</th>
                  <th>Item</th>
                  <th>Sub-Item</th>
                  <th>Nível de Maturidade</th>
                  <th>Criticidade</th>
                  <th>Esforço para Implantação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>1 jun de 2022</td>
                  <td>Dispositivos</td>
                  <td>Chrome</td>
                  <td>Configurações</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Baixa</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>1 jun de 2022</td>
                  <td>Diretório</td>
                  <td>Edifícios e recursos</td>
                  <td>Sincronização do diretório</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>1 jun de 2022</td>
                  <td>Apps</td>
                  <td>Apps do Google Workspace e Marketplace</td>
                  <td>LDAP</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>1 jun de 2022</td>
                  <td>Dispositivos</td>
                  <td>Conectores</td>
                  <td>null</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>1 jun de 2022</td>
                  <td>Diretório</td>
                  <td>Edifícios e recursos</td>
                  <td>Configurações do diretório</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>1 jun de 2022</td>
                  <td>Relatórios</td>
                  <td>Destaques</td>
                  <td>null</td>
                  <td>null</td>
                  <td>Média</td>
                  <td>Baixa</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>1 jun de 2022</td>
                  <td>Dispositivos</td>
                  <td>Visão Geral</td>
                  <td>null</td>
                  <td>null</td>
                  <td>Média</td>
                  <td>Média</td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>1 jun de 2022</td>
                  <td>Apps</td>
                  <td>Apps do Google Workspace e Marketplace</td>
                  <td>Configurações</td>
                  <td>null</td>
                  <td>Média</td>
                  <td>Média</td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>1 jun de 2022</td>
                  <td>Dispositivos</td>
                  <td>Chrome</td>
                  <td>Apps e Extensões</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Baixa</td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td>1 jun de 2022</td>
                  <td>Diretório</td>
                  <td>Edifícios e recursos</td>
                  <td>Gerenciar Recursos</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>1 jun de 2022</td>
                  <td>Dispositivos</td>
                  <td>Impressoras</td>
                  <td>null</td>
                  <td>null</td>
                  <td>Baixa</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td>1 jun de 2022</td>
                  <td>Diretório</td>
                  <td>Unidades Organizacionais</td>
                  <td>null</td>
                  <td>null</td>
                  <td>Alta</td>
                  <td>Baixa</td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td>1 jun de 2022</td>
                  <td>Segurança</td>
                  <td>Controle de Dados e Acesso</td>
                  <td>Apps menos seguros</td>
                  <td>null</td>
                  <td>Alta</td>
                  <td>Alta</td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td>1 jun de 2022</td>
                  <td>Segurança</td>
                  <td>Central de Segurança</td>
                  <td>Ferramenta de Investigação</td>
                  <td>null</td>
                  <td>Média</td>
                  <td>Média</td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td>1 jun de 2022</td>
                  <td>Faturamento</td>
                  <td>Contas para pagamento</td>
                  <td>null</td>
                  <td>null</td>
                  <td>null</td>
                  <td>null</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Filters>
      </DivRow>
    </Container>
  );
};

export default Dashboard;
