import styled from "styled-components";

interface IWidth {
  white?: boolean;
  wide?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  height: 100%;
  width: 100%;
`;

export const DivRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Filters = styled.div<IWidth>`
  background-color: ${(props) => (props.white ? "var(--white)" : "#bdbdbd")};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  height: auto;
  margin-right: 4px;
  padding-bottom: 10px;
  width: ${(props) => (props.wide ? props.wide : "280px")};

  & > p {
    background-color: var(--primary-lighter);
    border-radius: 8px 8px 6px 6px;
    font-weight: 700;
    padding: 8px;
    text-align: center;
    width: 100%;
  }

  select {
    border: 1px solid var(--muted);
    border-radius: 6px;
    box-shadow: 0 0 6px 1px var(--muted);
    background-color: transparent;
    margin-top: 10px;
    height: 50px;
    width: 90%;
    padding-left: 20px;
  }

  .graphic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    width: 100%;
    p {
      font-size: 0.75rem;
      margin-top: 10px;
    }

    div:nth-of-type(1),
    div:nth-of-type(3) {
      min-width: 150px;
      width: 24% !important;

      #reactgooglegraph-2,
      #reactgooglegraph-5 {
        width: 100% !important;
      }
    }
  }

  .graphicMaturity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    width: 100%;

    div {
      min-width: 660px;
      width: 50%;

      #reactgooglegraph-6 {
        width: 100%;
      }
    }

    table {
      width: 30%;
    }
  }

  .tableDetailing {
    width: 100%;
  }

  .description {
    width: 50%;
  }

  .tooltip {
    border-radius: 0.25rem;
    background: #26313c;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
    max-height: 80px;
    max-width: 110px;
  }

  .tooltip > h4 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .tooltip > p {
    color: var(--primary-lighter);
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0;
  }
`;

export const Card = styled.div`
  border: 1px solid var(--muted);
  border-radius: 8px;
  box-shadow: 0 0 6px 1px var(--muted);
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  width: 90%;
  text-align: center;

  p:nth-of-type(1) {
    font-weight: 800;
  }

  p {
    margin: 10px;
  }
`;

export const DivResume = styled.div`
  border: 1px solid var(--muted);
  border-radius: 8px;
  box-shadow: 0 0 6px 1px var(--muted);
  color: #7b7b7b;
  font-weight: 600;
  margin-top: 20px;
  height: auto;
  width: 90%;
  padding: 10px;

  p {
    color: var(--black);
    font-size: 1.5rem;
    text-align: right;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 20px;
  height: 100%;
  width: 100%;

  thead {
    background-color: #ffa800;
    text-align: left;
  }
  table tr th,
  table tbody tr td,
  table tfoot tr td {
    padding: 4px 6px;
  }
  tbody tr:nth-child(odd) {
    background: #7db343;
  }

  tbody tr {
    height: 30px;
  }

  tbody tr td {
    font-size: 0.9rem;
  }
  tbody tr td:nth-of-type(1) {
    padding-left: 8px;
  }

  tbody tr:hover {
    background-color: var(--muted);
  }
`;
