import styled from "styled-components";

import ButtonLayout from "../Button";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface IHeaderProps {
  direction?: string;
}

export const HeaderContent = styled.div<IHeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 559px) {
    flex-direction: ${(props) => (props.direction ? props.direction : "row")};
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const HeaderCustomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

interface ISearchInputProps {
  header?: string;
}

export const SearchInput = styled.input<ISearchInputProps>`
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--input-border-blurred);
  background-color: transparent;
  margin-top: -8px;
  margin-bottom: 16px;
  padding: 0 8px;
  display: ${(props) => (props.header === "true" ? "none" : "block")};

  @media (max-width: 559px) {
    display: ${(props) => (props.header === "true" ? "block" : "none")};
    margin-top: 8px;
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 24px;

  & span {
    font-size: 14px;
    color: var(--muted);
  }
`;

export const Content = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  overflow: auto;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > p {
    margin-bottom: 16px;
  }
`;

export const FormContent = styled.div`
  background-color: var(--white);

  border-radius: 2px;
  border: 1px solid var(--opacity-background);
  overflow: auto;
  padding: 16px;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const ActionsContainer = styled.div<IHeaderProps>`
  width: auto;
  display: flex;
  gap: 16px;

  & button {
    width: auto;
  }

  @media (max-width: 559px) {
    flex-direction: ${(props) => (props.direction ? props.direction : "row")};
    margin-top: ${(props) =>
      props.direction && props.direction === "column" ? "8" : "0"}px;
    width: ${(props) =>
      props.direction && props.direction === "column" ? "100%" : "auto"};

    button {
      width: ${(props) =>
        props.direction && props.direction === "column" ? "100%" : "auto"};
    }
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 12px;
  padding: 10px;

  @media (max-width: 559px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  & > button {
    margin-top: 32px;
    font-size: 16px;
    padding: 0px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: var(--white);

  border: 1px solid var(--opacity-background);

  @media (max-width: 559px) {
    flex-direction: column;
    justify-content: center;

    & div {
      margin-left: 0;
      margin-bottom: 8px;
    }
  }
`;

export const UploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  & + label * {
    pointer-events: none;
  }
`;

export const UploadLabel = styled.label`
  font-weight: 500;
  color: white;
  background-color: var(--primary);
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 12px;
  &:hover {
    filter: brightness(0.95);
  }
`;

export const UploadedContent = styled.div`
  border: 1px solid var(--opacity-background);
  padding: 8px;
  border-radius: 4px;

  & span {
    font-weight: 700;
    font-family: "Courier New", Courier, monospace !important;
  }

  & p {
    font-family: "Courier New", Courier, monospace !important;
  }
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 16px;
  }

  & strong {
    font-size: 12px;
    margin-bottom: 16px;
  }

  & span {
    color: blue;
    font-style: italic;
    text-decoration: underline;
    text-decoration-color: blue;
    cursor: pointer;
  }
`;

export const UploadedItemActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const NoPrivideContent = styled.div`
  border: 1px solid var(--opacity-background);
  padding: 8px;
  border-radius: 4px;
  width: fit-content;

  & div.flex {
    border-bottom: 1px solid black;
    display: flex;
    margin-bottom: 12px;
    padding-bottom: 4px;
  }

  & span {
    font-weight: 600;
    display: flex;
    margin-right: 12px;
  }

  & p {
    margin-left: 8px;
  }

  & hr {
    margin-bottom: 12px;
  }
`;
