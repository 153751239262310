import styled from "styled-components";

interface IWidth {
  heigh?: string;
  wide?: string;
  minWide?: string;
  mTop?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  padding: 16px;
`;

export const DivLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 63%;

  h2 {
    color: var(--success);
    font-size: 1.8rem;
    margin-left: 10px;
  }

  p {
    margin-left: 10px;
  }

  .text {
    background: var(--white);
    display: flex;
    align-items: center;
    margin: 0 8px 8px 0;
    padding: 20px;
    height: 200px;
    width: 48%;
    min-width: 360px;
    word-wrap: break-word;
    text-align: center;
  }
`;

export const DivRight = styled.div`
  height: 100%;
  width: 37%;

  .right {
    text-align: right;
  }
`;

export const DivRow = styled.div`
  display: flex;
`;

export const DivColumn = styled.div<IWidth>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.wide ? `${props.wide} !important` : "auto")};

  .description {
    font-size: 0.7rem !important;
  }
`;

export const Card = styled.div<IWidth>`
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--muted);
  margin: 0 8px 8px 0;
  width: ${(props) => (props.wide ? props.wide : "48%")};
  min-width: ${(props) => (props.minWide ? props.minWide : "430px")};
  height: ${(props) => (props.heigh ? props.heigh : "200px")};
  padding: 10px;

  &:hover {
    /* box-shadow: 0 0 0.2em #848587; */
    box-shadow: 0 0 5px 1px var(--muted);
    border: 1px solid transparent;
  }

  h3 {
    color: #848587;
    font-weight: 300;
  }

  img {
    width: 40%;
  }

  .right {
    width: 50%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
    margin-left: auto;
    margin-top: 40px;
    height: 100%;
    width: 60%;
  }

  .content > p {
    font-size: 1.8rem;
    margin-left: 6px;
    color: var(--primary);
  }

  .content > h3 {
    font-size: 1.5rem;
  }

  .footer {
    display: flex;
    align-items: center;
  }

  .footer > p {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    margin-left: 6px;
  }

  .tooltip {
    border-radius: 0.25rem;
    background: #26313c;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
    max-height: 80px;
    max-width: 110px;
  }

  .tooltip > h4 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .tooltip > p {
    color: var(--primary-lighter);
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0;
  }
`;

export const CardSkill = styled.div<IWidth>`
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--muted);
  margin-top: ${(props) => (props.mTop ? props.mTop : "8px")};
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: 0;

  width: 100%;
  min-height: 210px;
  height: auto;
  padding: 10px;

  &:hover {
    box-shadow: 0 0 6px 1px var(--muted);
    border: 1px solid transparent;
  }

  table {
    border: 2px solid var(--primary);
    border-collapse: collapse;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 20px;
  }
  thead {
    background-color: var(--primary);
    color: var(--white);
    text-align: left;
  }
  table tr th,
  table tbody tr td,
  table tfoot tr td {
    padding: 4px 6px;
  }
  tfoot {
    font-weight: 700;
  }
  tbody tr {
    line-height: 1.7rem;
  }

  h3 {
    color: #848587;
    font-weight: 300;
  }

  .container-left {
    width: 50%;
    margin-right: auto;
  }

  .container {
    width: 50%;
    margin-left: auto;
  }

  .container-left > h3 {
    color: #9ea4ac;
    font-size: 3rem;
    text-align: right;
    margin-right: 20px;
  }

  .paragraph {
    display: flex;
    justify-content: space-between;
  }

  .item {
    width: 100%;
    background: #ddd;
  }

  .skills {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
  }

  .first {
    width: 90%;
    background: red;
  }

  .second {
    width: 80%;
    background: orange;
  }

  .third {
    width: 65%;
    background: green;
  }

  .graphics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .graphic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 28%;

    div {
      display: flex;
      align-items: center;
      width: 100%;
    }
    p {
      font-size: 1.3rem;
    }

    span {
      margin-left: 18%;
      text-align: right;

      svg {
        margin-right: 6px;
      }
    }

    .success {
      color: var(--success);
    }

    .warning {
      color: var(--warning);

      svg {
        rotate: 180deg;
      }
    }
  }

  .right {
    margin-bottom: 5px;
    width: 100%;
  }

  .tooltip {
    border-radius: 0.25rem;
    background: #26313c;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;
    max-height: 80px;
    max-width: 110px;
  }

  .tooltip > h4 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .tooltip > p {
    color: var(--primary-lighter);
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0;
  }
`;

export const DivStorage = styled.div`
  background-color: var(--primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;

  div {
    width: 50%;
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    background-color: var(--white);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 14px 10px;
    width: 96%;

    span {
      color: var(--primary);
      font-weight: 300;
    }

    p {
      color: var(--primary-darker);
      font-size: 1.3rem;
      font-weight: 600;
      margin-top: 10px;
      text-align: right;
    }

    .danger {
      color: var(--warning);
    }
  }

  & > div > p {
    color: var(--white);
    width: 92%;
    text-align: center;
  }
`;

export const DivMain = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  color: #848587;
  font-weight: 500;
  margin-left: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkPrimary = styled.a`
  color: var(--primary);
  font-weight: 500;
  text-decoration: none;
  margin-top: 4px;

  &:hover {
    text-decoration: underline;
  }
`;
